import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import ControledAccordion from 'components/ControledAccordion';
import getSchemaScript from './utils';
import { FaqProps } from './model';
import './Faq.scss';

const Faq: FC<FaqProps> = ({ title, items, selectedIndex, isExpanded }) => (
  <div className="gs-symptom-section__panel-block gs-faq">
    {items?.length ? <Helmet script={getSchemaScript(items)} /> : null}
    {title ? <DangerouslySetInnerHtml className="gs-faq__title" element="h2" html={title} /> : null}
    {items?.length ? <ControledAccordion {...{ items, selectedIndex, isExpanded }} /> : null}
  </div>
);

export default Faq;
