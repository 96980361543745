import React, { FC, useState } from 'react';
import { Element } from 'react-scroll';
import Container from 'react-bootstrap/Container';
import classnames from 'classnames';

import ConditionalWrapper from 'gatsby-theme-gaviscon/src/components/common/ConditionalWrapper/CondidionalWrapper';
import DangerouslySetInnerHtml from 'gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml';
import DiscoveryBlock from 'gatsby-theme-gaviscon/src/components/DiscoveryBlock';
import BrandHistory from 'gatsby-theme-gaviscon/src/components/BrandHistory';
import GatsbyImage from 'gatsby-theme-gaviscon/src/components/common/GatsbyImage';
import useScreenRecognition from 'gatsby-theme-gaviscon/src/hooks/useScreenRecognition';
import responsiveObj from 'gatsby-theme-gaviscon/src/components/HomeBanner/HomeBannerSlider/constants';

import { DiscoverySectionProps } from './model';
import './DiscoveryTextSection.scss';
import ControlledCarousel from 'gatsby-theme-gaviscon/src/components/ControlledCarousel';

const DiscoveryTextSection: FC<DiscoverySectionProps> = ({
  title,
  subtitle,
  description,
  image,
  imageAlt,
  showInMenu,
  color,
  blocks,
  history,
  imagePosition,
  anchorName,
}) => {
  const [activeStateValue, setActiveStateValue] = useState<number>(0);
  const { isMobile } = useScreenRecognition();
  const sectionCSSClassNames = classnames({
    'gs-discovery-section': true,
    [`gs-discovery-section--bg-${color}`]: color,
    [`gs-discovery-section--image-${imagePosition[0]}`]: imagePosition.length,
    'gs-discovery-section--history': history?.length,
  });

  const blocksCSSClassNames = classnames('gs-discovery-section__blocks', {
    'gs-discovery-section__blocks--offset': blocks?.length > 2,
  });

  const imageSwitchHandler = ind => {
    setActiveStateValue(ind);
  };

  return (
    <ConditionalWrapper
      condition={showInMenu === '1'}
      wrapper={(children) => (
        <Element name={anchorName || title} className="element">
          {children}
        </Element>
      )}
    >
      {title !== 'The Science' ? (
        <div className={sectionCSSClassNames}>
          <div className="gs-discovery-section__crop">
            <Container fluid>
              <div className="gs-discovery-section__intro">
                <div className="gs-discovery-section__intro-text">
                  <DangerouslySetInnerHtml
                    element="strong"
                    className="gs-discovery-section__intro-title"
                    html={title}
                  />
                  <DangerouslySetInnerHtml
                    element="h2"
                    className="gs-discovery-section__intro-subtitle"
                    html={subtitle}
                  />
                  <DangerouslySetInnerHtml
                    className="gs-discovery-section__intro-description"
                    html={description}
                  />
                </div>
                {history?.length ? (
                  <BrandHistory history={history} />
                ) : (
                  <div className="gs-discovery-section__intro-image">
                    {image?.fallbackUrl ? <GatsbyImage fluid={image} alt={imageAlt} /> : null}
                  </div>
                )}
              </div>
              {blocks?.length ? (
                <div className={blocksCSSClassNames}>
                  {blocks.map(({ properties: blockProperties }) => (
                    <DiscoveryBlock key={blockProperties.title} {...blockProperties} />
                  ))}
                </div>
              ) : null}
            </Container>
          </div>
        </div>
      ) : (
        <div className={`${sectionCSSClassNames} gs-discovery-section-science`}>
          <div className="gs-discovery-section__crop">
            <Container fluid>
              <div className="gs-discovery-section__intro">
                <div className="gs-discovery-section__intro-text">
                  <DangerouslySetInnerHtml
                    element="strong"
                    className="gs-discovery-section__intro-title"
                    html={title}
                  />
                  <DangerouslySetInnerHtml
                    element="h2"
                    className="gs-discovery-section__intro-subtitle"
                    html={subtitle}
                  />
                  {!isMobile ?
                    (<div className="intro-text-desktop">
                      {blocks?.length ? (
                        <div className='gs-discovery-section__listing'>
                          {blocks.map(({ properties: blockProperties }, index) => (
                            <div className={blocksCSSClassNames} key={blockProperties.title}>
                                <div className={`gs-discovery-section__listing-clickable-paragraph`}>
                                  <span>{`${index + 1}.`}</span>
                                  <DiscoveryBlock hideImageOnLoad={true} isActive={index === activeStateValue} imageSwitchHandler={imageSwitchHandler} index={index} {...blockProperties} />
                                </div>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>) : null
                  }
                </div>
                {history?.length ? (
                  <BrandHistory history={history} />
                ) : (
                  !isMobile ?
                    (<div className="gs-discovery-section__intro-image" >
                      {blocks.map(({ properties: blockProperties }, index) => (
                        <DiscoveryBlock key={blockProperties.title} {...blockProperties} index={index} isActive={index === activeStateValue} />
                      ))}
                    </div>) : null
                )}
                {isMobile ?
                  (<div className="intro-text-mobile">
                    <ControlledCarousel
                      dotsOutside
                      navigatorsOutside={!isMobile}
                      showDots
                      showNavigators
                      classes="gs-science-carousel"
                      responsiveObj={responsiveObj}
                    >
                      {blocks.map(({ properties: blockProperties }, index) => (
                        <div className={blocksCSSClassNames} key={blockProperties.title}>
                            <div className='gs-discovery-section__listing-clickable-paragraph'>
                              <span>{`${index + 1}.`}</span>
                              <DiscoveryBlock key={blockProperties.title} {...blockProperties} />
                            </div>
                        </div>
                      ))}
                    </ControlledCarousel>
                  </div>) : null
                }
              </div>
              <DangerouslySetInnerHtml
                className="gs-discovery-section__intro-description"
                html={description}
              />
            </Container>
          </div>
        </div>
      )}
    </ConditionalWrapper>
  );
};

export default DiscoveryTextSection;
