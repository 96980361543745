import React, { FC } from 'react';

import IconCustom from 'gatsby-theme-gaviscon/src/components/common/IconCustom';
import GatsbyImage from 'gatsby-theme-gaviscon/src/components/common/GatsbyImage';
import DangerouslySetInnerHtml from 'gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml';

import { DiscoveryBlockProps } from './model';
import './DiscoveryBlock.scss';

const DiscoveryBlock: FC<DiscoveryBlockProps> = ({
  description,
  title,
  image,
  imageAlt,
  isActive,
  index,
  imageSwitchHandler,
  hideImageOnLoad
}) => (
  <div className={`gs-discovery-block ${isActive ? 'active-paragraph' : ''}`} data-testid="discovery-block">
    {image?.fallbackUrl ? (
      hideImageOnLoad ? '' : (<GatsbyImage className="gs-discovery-block__image" fluid={image} alt={imageAlt} />)
    ) : null}
    <strong onClick={ hideImageOnLoad ? () => imageSwitchHandler(index) : undefined} className="gs-discovery-block__title">
      <IconCustom icon="tick" />
      <DangerouslySetInnerHtml html={title} />
    </strong>
    <DangerouslySetInnerHtml className="gs-discovery-block__description" html={description} />
  </div>
);

export default DiscoveryBlock;
