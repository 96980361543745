import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import './TextSection.scss';

const TextSection: FC<HomePageTypes.TTextSectionProps> = ({ description }) => {
  return (
    <div className="text-section" data-testid="text-section" id="text-section">
      <Container fluid>
        <div className="text-section__wrap">
          {description ? (
            <DangerouslySetInnerHtml className="text-section__description" html={description} />
          ) : null}
        </div>
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentTextSection on TTextSectionStructure {
    structure
    properties {
      description
    }
  }
`;

export default TextSection;
