import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Element } from 'react-scroll';

import Layout from 'components/Layout';

import HomeBanner from 'components/HomeBanner';
import ProductFinder from 'components/ProductFinder';
import InfoSection from 'components/InfoSection';
import BodyRenderer from 'components/common/BodyRenderer';
import RelatedProducts from 'components/RelatedProducts';
import Testimonials from 'components/Testimonials';
import WhereToBuy from 'components/WhereToBuy';
import DiscoveryTextSection from 'components/DiscoveryTextSection';
import RelatedArticles from 'components/RelatedArticles';
import BootsBanner from 'components/BootsBanner';
import CustomRelatedArticles from 'components/CustomRelatedArticles';
import FaqSection from 'components/FaqSection';
import TextSection from 'components/TextSection';
import { GUARDIUM_DESIGN } from 'utils/constants';

import './HomePage.scss';

const elements = {
  'Product Finder': ({ properties }, keyId) => <ProductFinder key={keyId} {...properties} />,
  'Info Section': ({ properties }, keyId) => <InfoSection key={keyId} {...properties} />,
  'Related Products': ({ properties }, keyId) => <RelatedProducts key={keyId} {...properties} />,
  'Related Articles': ({ properties }, keyId) => {
    const { relatedArticlesLimit, ...props } = properties;

    return <RelatedArticles key={keyId} limit={relatedArticlesLimit} {...props} />;
  },
  'Testimonials Carousel': ({ properties }, keyId) => <Testimonials key={keyId} {...properties} />,
  'Where to buy': ({ properties }, keyId) => <WhereToBuy key={keyId} {...properties} />,
  'Discovery Section': ({ properties }, keyId) => (
    <DiscoveryTextSection key={keyId} {...properties} />
  ),
  'Boots Banner': ({ properties }, keyId) => <BootsBanner key={keyId} {...properties} />,
  'Custom Related Articles': ({ properties }, keyId) => {
    const { relatedArticlesLimit, ...props } = properties;

    return <CustomRelatedArticles key={keyId} limit={relatedArticlesLimit} {...props} />;
  },
  'FAQ block': ({ properties }, keyId) => <FaqSection key={keyId} {...properties} />,
  'Text Section': ({ properties }, keyId) => <TextSection key={keyId} {...properties} />,
};

const HomePage: FC<HomePageTypes.IPageBody> = ({
  data: {
    umbracoHome: {
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      openGraphImageUrl,
      body,
      topBanner,
      tags,
    },
    relatedArticles,
    relatedProducts,
    commonSettings,
    siteSettings,
    mobileAppPromoBanner,
    header,
    footer,
  },
  pageContext: { relatedProductsLinks },
}) => {
  const articles = relatedArticles?.nodes;
  const isGuardiumDesign = !!tags?.find(({ name }) => name === GUARDIUM_DESIGN);

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        openGraphImageUrl,
      }}
      siteSettings={siteSettings}
      commonSettings={commonSettings}
      header={header}
      footer={footer}
      mobileAppPromoBanner={mobileAppPromoBanner}
      isGuardiumDesign={isGuardiumDesign}
    >
      <HomeBanner topBanner={topBanner} />
      <Element name="main-content" className="element">
        <BodyRenderer
          bodyData={body}
          bodyItemProps={{ ...{ articles, relatedProducts, commonSettings, relatedProductsLinks } }}
          bodyStructure={elements}
        />
      </Element>
    </Layout>
  );
};

export const query = graphql`
  query HomeQuery(
    $relatedProductsLinks: [String]
    $relatedArticlesLinks: [String]
    $link: String = ""
    $lang: String = ""
    $articleSortInput: UmbracoArticlesSortInput
  ) {
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    commonSettings(lang: { eq: $lang }) {
      blocks {
        structure
        properties {
          ...FragmentDefaultCommonSettingsProps
          preTitle
          secText
          closeModalText
          minText
        }
      }
    }
    header(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footer(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    umbracoHome(link: { eq: $link }, lang: { eq: $lang }) {
      topBanner {
        ...FragmentHomePageTopBanner
      }
      title
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        key
        value
      }
      openGraphImageUrl
      link
      id
      relatedArticlesLinks
      body {
        ... on TFAQblockStructure {
          structure
          properties {
            title
            color
            selectedIndex
            isExpanded
            items {
              properties {
                answer
                question
              }
            }
          }
        }
        ... on TCustomRelatedArticlesStructure {
          structure
          properties {
            title
            description
            articlesBlock {
              properties {
                title
                image {
                  ...FragmentGatsbyProps
                  gatsbyImage {
                    childImageSharp {
                      fluid(maxWidth: 350) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                label
                link {
                  url
                }
                readMore
                shortText
                shortTitle
                text
                button {
                  name
                  url
                }
              }
              structure
            }
          }
        }
        ... on TRelatedArticlesStructure {
          structure
          properties {
            title
            relatedArticlesLimit: limit
            anchorName
            showInMenu
            menuTitle
          }
        }
        ... on TBootsBannerStructure {
          structure
          properties {
            labelText
            title
            button {
              name
              url
              icon
            }
            imageBtn {
              ...FragmentGatsbyImage
            }
            imageBtnAlt
            imageBg {
              ...FragmentGatsbyImage
            }
            imageBgAlt
            image {
              ...FragmentGatsbyImage
            }
            imageMobile {
              ...FragmentGatsbyImage
            }
            imageAlt
          }
        }
        ... on TTestimonialsCarouselStructure {
          structure
          properties {
            color
            title
            slides {
              properties {
                imageAlt
                image {
                  ...FragmentGatsbyImage
                }
                description
                ariaLink
                link {
                  name
                  url
                }
              }
            }
          }
        }
        ... on TProductFinderStructure {
          structure
          properties {
            description
            imageAlt
            subTitle
            title
            link {
              url
              name
            }
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                ...FragmentMiddleImage
              }
              mobileImage {
                childImageSharp {
                  fluid(maxWidth: 250) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        ... on TDiscoverySectionStructure {
          structure
          properties {
            blocks {
              properties {
                description
                image {
                  ...FragmentGatsbyImage
                }
                imageAlt
                title
              }
            }
            color
            description
            image {
              ...FragmentGatsbyImage
            }
            imageAlt
            showInMenu
            title
            anchorName
            imagePosition
          }
        }
        ... on TRelatedProductsStructure {
          structure
          properties {
            ariaAllProducts
            limit
            title
            linkAllProducts {
              name
              url
            }
            isFamily
            hideVariant
          }
        }
        ... on TInfoSectionStructure {
          structure
          properties {
            imageAlt
            imageAlign
            video {
              structure
              properties {
                title
                url
                link
                duration
                ariaLabel
                videoPreviewPosterAlt
                videoPreviewPoster {
                  ...FragmentGatsbyImage
                }
                playButtonAria
              }
            }
            description
            title
            link {
              name
              url
            }
            ctaButtonType
            ariaCTA
            image {
              ...FragmentGatsbyImage
            }
            ctaButtonNotice
          }
        }
        ... on TWheretobuyStructure {
          structure
          properties {
            color
            title
            listMode
            limit
            limitMobile
            cards {
              properties {
                imageAlt
                type
                title
                link {
                  url
                  target
                  queryString
                }
                image {
                  ...FragmentGatsbyImage
                }
              }
            }
          }
        }
        ... on TTextSectionStructure {
          structure
          properties {
            description
          }
        }
      }
      tags {
        name
      }
    }
    relatedProducts: allProduct(filter: { link: { in: $relatedProductsLinks } }) {
      nodes {
        link
        productImage {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentSmallImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 210) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        productImageAlt
        cardTitle
        familyCardTitle
        tickItems {
          value
        }
        tags {
          isProductFamily
          color {
            label
          }
        }
        featureItems {
          properties {
            icon
            label
          }
        }
        cartFakeLink
        imageFamily {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentSmallImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 210) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        linkFamily {
          url
        }
        currentFormat {
          properties {
            label
          }
        }
      }
    }
    relatedArticles: allUmbracoArticles(
      filter: { link: { in: $relatedArticlesLinks } }
      sort: $articleSortInput
    ) {
      nodes {
        id
        link
        title
        shortTitle
        label
        text
        alt
        shortText
        readMore
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentSmallImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 210) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        tags {
          articleCategory
          id
          name
        }
      }
    }
  }
`;

export default HomePage;
