import React, { FC, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import Container from 'react-bootstrap/Container';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { parseBoolean } from 'utils/parseHelpers';
import Button from 'components/common/Button';
import useScreenRecognition from 'hooks/useScreenRecognition';
import ControlledCarousel from 'components/ControlledCarousel';
import WhereToBuyCard from './WhereToBuyCard';
import responsiveObj from './constants';
import './WhereToBuy.scss';

const WhereToBuy: FC<ProductPageTypes.IWhereToBuy> = ({
  title,
  color,
  cards,
  listMode,
  limit,
  limitMobile,
  btnMore,
  ariaMore,
}) => {
  const sectionCSSClassNames = classnames('where-to-buy', {
    [`where-to-buy--bg-${color[0]}`]: color.length,
  });
  const { isDesktop } = useScreenRecognition();
  const [toShow, setToShow] = useState<number>(0);

  const deviceToShow = {
    mobile: limitMobile || 6,
    desktop: limit || 9,
  };
  const stepLoad = isDesktop ? deviceToShow.desktop : deviceToShow.mobile;
  const loadMore = useCallback(() => setToShow(toShow + stepLoad), [toShow]);

  useEffect(() => {
    setToShow(stepLoad);
  }, [stepLoad]);

  return (
    <div className={sectionCSSClassNames} data-testid="where-to-buy">
      <div className="where-to-buy__target" id="where-to-buy" />
      <Container fluid>
        {title ? (
          <DangerouslySetInnerHtml element="h2" html={title} className="where-to-buy__title" />
        ) : null}
        {!parseBoolean(listMode) ? (
          <ControlledCarousel
            dotsOutside
            showDots
            showNavigators
            classes="where-to-buy__carousel"
            responsiveObj={responsiveObj}
          >
            {cards?.length
              ? cards.map(({ properties }) => (
                  <WhereToBuyCard key={properties.title} {...properties} />
                ))
              : null}
          </ControlledCarousel>
        ) : (
          <div className="where-to-buy__grid">
            {cards?.length
              ? cards
                  .slice(0, toShow)
                  .map(({ properties }) => (
                    <WhereToBuyCard key={properties.title} {...properties} />
                  ))
              : null}
          </div>
        )}
        {btnMore && toShow < cards?.length ? (
          <div className="where-to-buy__more">
            <Button
              ariaLabel={ariaMore}
              clickHandler={loadMore}
              classes="where-to-buy__more-btn"
              variant="secondary"
            >
              {btnMore}
            </Button>
          </div>
        ) : null}
      </Container>
    </div>
  );
};

export default WhereToBuy;
