import React, { FC, useCallback } from 'react';
import ExternalLink from 'components/common/ExternalLink';
import GatsbyImage from 'components/common/GatsbyImage';
import classnames from 'classnames';
import { gtmService } from 'services/gtmService';

const WhereToBuyCard: FC<ProductPageTypes.IWhereToBuyCard> = ({
  title,
  type,
  imageAlt,
  image,
  link,
  classes,
}) => {
  const path = (link?.[0]?.url || '').concat(link?.[0]?.queryString || '');
  const cardCSSClassNames = classnames('where-to-buy__card', classes);

  const handleRetailerClick = useCallback(() => {
    gtmService.emitRetailerClick({}, title);
  }, []);

  return (
    <ExternalLink
      to={path}
      target={link?.[0]?.target}
      className={cardCSSClassNames}
      data-testid="where-to-buy"
      onClick={handleRetailerClick}
    >
      <div className="where-to-buy__card-image">
        {image?.fallbackUrl ? (
          <GatsbyImage
            objectFit="contain"
            className="where-to-buy__card-image-img"
            fluid={image}
            alt={imageAlt}
          />
        ) : null}
      </div>
      {title || type ? (
        <div className="where-to-buy__card-text">
          {title ? <strong className="where-to-buy__card-title">{title}</strong> : null}
          {type ? <p className="where-to-buy__type">{type}</p> : null}
        </div>
      ) : null}
    </ExternalLink>
  );
};

export default WhereToBuyCard;
