import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';

import CardRelatedArticle from 'components/CardRelatedArticle';
import ControlledCarousel from 'components/ControlledCarousel';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import responsiveObj from './constants';
import './CustomRelatedArticles.scss';

const CustomRelatedArticles: FC<HomePageTypes.ICustomRelatedArticleProps> = ({
  articlesBlock,
  title,
  description,
}) =>
  articlesBlock?.length > 0 ? (
    <div className="gs-custom-related-articles">
      <Container fluid>
        {title ? (
          <DangerouslySetInnerHtml
            element="h2"
            className="gs-related-articles__title"
            html={title}
          />
        ) : null}

        {description ? (
          <DangerouslySetInnerHtml className="info-section__description" html={description} />
        ) : null}

        <ControlledCarousel
          dotsOutside
          showDots
          showNavigators
          classes="gs-articles-carousel"
          responsiveObj={responsiveObj}
          navigatorsOutside
        >
          {articlesBlock.map((article, index) => {
            const id = `${article.properties.title}_${index}`;
            const articleItem = {
              ...article.properties,
              link: article.properties?.link?.[0].url,
              tags: [
                {
                  id: '0',
                  name: article.properties?.readMore,
                  articleCategory: '1',
                  link: [
                    {
                      url: article.properties?.link?.[0].url,
                      name: article.properties?.readMore,
                    },
                  ],
                },
              ],
            };

            return <CardRelatedArticle key={id} articleData={{ ...articleItem }} />;
          })}
        </ControlledCarousel>
      </Container>
    </div>
  ) : null;
export default CustomRelatedArticles;
